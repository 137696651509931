import { Control, Controller, FieldPath } from 'react-hook-form';

import { ClassName, I18nText, IsDisabled } from '../../../types';
import { UserID } from '../../../main/users/usersTypes';

import { UsersAvatarsFieldControl } from './components/UsersAvatarsFieldControl';

import { IconsEnum } from '../../../assets/icons/types';

interface UsersAvatarsFieldProps<FormDataType> {
  control: Control<FormDataType>;
  disabled?: IsDisabled;
  i18nLabel?: I18nText;
  name: FieldPath<FormDataType>;
  addButtonIcon?: IconsEnum;
  addButtonIconClassName?: ClassName;
  addButtonI18nText?: I18nText;
  addButtonClassName?: ClassName;
  wrapperClassName?: ClassName;
}
function UsersAvatarsField<FormDataType>({
  control,
  disabled,
  i18nLabel,
  name,
  addButtonIcon,
  addButtonIconClassName,
  addButtonI18nText,
  addButtonClassName,
  wrapperClassName
}: UsersAvatarsFieldProps<FormDataType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <UsersAvatarsFieldControl
          disabled={disabled}
          fieldName={name}
          i18nLabel={i18nLabel}
          onChange={onChange}
          value={value as UserID[]}
          addButtonIcon={addButtonIcon}
          addButtonIconClassName={addButtonIconClassName}
          addButtonI18nText={addButtonI18nText}
          addButtonClassName={addButtonClassName}
          wrapperClassName={wrapperClassName}
        />
      )}
    />
  );
}

export default UsersAvatarsField;
